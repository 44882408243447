@import "~bootstrap/scss/bootstrap";
@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800&family=Urbanist:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Just+Another+Hand&display=swap");

body {
  font-family: "Rubik", sans-serif !important;
  font-weight: 400;

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: "Urbanist", sans-serif;
  }

  h1 {
    font-size: 42px;
    font-weight: bold;
  }

  h2 {
    font-size: 32px;
    font-weight: bold;
  }

  h3 {
    font-size: 22px;
    font-weight: bold;
  }
}

.l-light {
  font-size: 22px;
  font-weight: 300;
  line-height: 26px;
}

.m-regular {
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
}

.s-regular {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}

.s-bold {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}

.xs-regular {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}

.xs-bold {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.xxs-regular {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
}

.xxs-bold {
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
}

.xxs-semi-bold {
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
}

.font-urbanist {
  font-family: "Urbanist", sans-serif;
}

.white-font {
  color: white;
}

.black-font {
  color: black;
}

.red-font {
  color: #d95040;
}

.lightblue-font {
  color: #326de6;
}

.blue-font {
  color: #4628dd;
}

.gray-font {
  color: #939393;
}

.lightgray-font {
  color: #939393;
}

.darkgray-font {
  color: #7c7c7c;
}

.green-font {
  color: #2f4f37;
}

.yellow-font {
  color: #dba02a;
}

.aqua-font {
  color: #56ceb2;
}

.white-background {
  background-color: white;
}

.blue-background {
  background-color: #4628dd;
}

.lightblue-background {
  background-color: #fbfaff;
}

.dark-blue-background {
  background-color: #142738;
}
.midnight-blue-background {
  background-color: #05192b;
}

.yellow-background {
  background-color: #f9cb5e;
}

.brown-background {
  background-color: #b59885;
}

.aqua-background {
  background-color: #56ceb2;
}

.violet-background {
  background-color: #8242ea;
}

.taupe-background {
  background-color: #b58585;
}

.orange-background {
  background-color: #f2e4cf;
}

.magenta-background {
  background-color: #a428dd;
}

.gray-background {
  background-color: #f6f6f6;
}

.lightgray-background {
  background-color: #fafafa;
}

.dark-gray-background {
  background-color: #c7c7c7;
}

.red-background {
  background-color: #d95040;
}

.tinted-red-background {
  background-color: #f6e6e6;
}

.tinted-blue-background {
  background-color: #e6f5f6;
}

.tinted-green-background {
  background-color: #eef6e6;
}

.beige-background {
  background-color: #f8f7f3;
}

.pink-background {
  background-color: #f1e6f6;
}

.violet-background {
  background-color: #e6e6f6;
}

.blue-azure-background {
  background-color: rgba(95, 134, 255, 0.18);
}

.hover-shadow:hover {
  -webkit-box-shadow: 5px 5px 24px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 5px 5px 24px 0px rgba(0, 0, 0, 0.25);
}

@media (min-width: 1200px) {
  .container {
    max-width: 1350px !important;
  }
}

.scrollable {
  overflow: auto;
}

.clickable {
  cursor: pointer;
}

.w-fit {
  width: fit-content;
}

.rotate-180 {
  transform: rotate(180deg);
}

.anchor {
  padding-top: 100px !important;
  margin-top: -100px !important;
}
