.job-offer-logo {
  width: 200px;
  height: 200px;
}

@media screen and (max-width: 768px) {
  .job-offer-logo {
    width: 100px;
    height: 100px;
  }
}
.mh-315 {
  max-height: 315px;
}
.mt-n4 {
  margin-top: -4%;
}

.background-offre {
  background-image: linear-gradient(transparent, white),
    url("../../../public/assets/images/header-offre-poei.png");
  background-size: cover;
  height: 20vw;
}

.duration {
  font-size: 24px;
  font-weight: bold;
}
.accordion-button {
  font-size: 18px;
}

.accordion {
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.2rem #dee2e6;
  --bs-accordion-active-bg: whitesmoke;
  --bs-accordion-btn-color: #000;
}

.accordion-button:not(.collapsed) {
  color: #000;
}
