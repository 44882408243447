.header-career {
  background-color: white;
  border-top: 7px solid #4628dd;
}

@media screen and (min-width: 576px) {
  .header-career {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -120px;
  }
  .mt-6 {
    margin-top: 10em;
  }
}

.mh-50 {
  max-height: 50px;
  max-width: 110px;
}

.nav {
  --bs-nav-link-color: black;
  --bs-nav-link-hover-color: dimgray;
}

.nav-tabs {
  border-bottom: 1px solid #e9e9e9 !important;
}

.nav-tabs .nav-link {
  border: none;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #4628dd;
  border-bottom: 3px solid #4628dd !important;
}
