button.navbar-toggler {
  background-color: #f8f6fe;
}

.my-img {
  height: 45px;
}

.menu-large {
  position: static !important;
}

.megamenu {
  padding: 10px;
  width: 100%;
}

.megamenu > div > li > ul {
  padding: 0;
  margin: 0;
}

.megamenu > div > li > ul > li {
  list-style: none;
}

.megamenu > div > li > ul > li > a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 1.428571429;
  color: #333333;
  white-space: normal;
}

.megamenu > div > li > ul > li > a:hover,
.megamenu > div > li > ul > li > a:focus {
  text-decoration: none;
  color: #262626;
  background-color: #f5f5f5;
}

.megamenu.disabled > a,
.megamenu.disabled > a:hover,
.megamenu.disabled > a:focus {
  color: #999999;
}

.megamenu.disabled > a:hover,
.megamenu.disabled > a:focus {
  text-decoration: none;
  background-color: transparent;
  background-image: none;
  cursor: not-allowed;
}

.megamenu.dropdown-header {
  color: #428bca;
  font-size: 18px;
}

@media (max-width: 768px) {
  .megamenu {
    margin-left: 0;
    margin-right: 0;
  }
  .megamenu > li {
    margin-bottom: 30px;
  }
  .megamenu > li:last-child {
    margin-bottom: 0;
  }
  .megamenu.dropdown-header {
    padding: 3px 15px !important;
  }
  .navbar-nav .open .dropdown-menu .dropdown-header {
    color: #fff;
  }
}

.w-250 {
  width: 210px;
}

.all-tab {
  border-top: 1px solid rgb(235, 235, 235);
  border-left: 1px solid rgb(235, 235, 235);
  border-right: 1px solid rgb(235, 235, 235);
}

.tab-menu {
  border-bottom: 1px solid rgb(235, 235, 235);
  border-left: 4px solid transparent;
  padding: 10px;
}
.tab-menu.active {
  border-left: 4px solid blue;
}
