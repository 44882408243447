.fiche-tag {
  vertical-align: middle;
  font-size: 12px;
  color: #595d63;
  display: flex;
  align-items: center;
}

.tag-bootcamp,
.tag-tp,
.tag-alternance {
  color: #151515;
  padding: 2px 5px 2px 5px;
  border-radius: 30px;
  font-family: "Urbanist", Sans-serif;
  font-size: 13px;
  font-weight: 300;
}
.tag-bootcamp {
  background-color: #fff1d3;
}
.tag-tp {
  background-color: #cdfdff;
}
.tag-alternance {
  background-color: #f6e6e6;
  margin-left: 2%;
}
.tag-separate {
  margin: 0 2%;
}

.formation-item {
  &:last-of-type {
    border-bottom: 1px solid #dee2e6;
  }

  &:hover .custom {
    background-color: #4628dd !important;
  }
}

.h3-style {
  font-size: 18px;
}

@media (max-width: 991px) {
  .h3-style {
    font-size: 14px !important;
  }
}

.custom {
  background-color: #dddddd;
  padding: 8px;
  border-radius: 50px !important;
  margin-right: 15px;
}
