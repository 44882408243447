.job-offer-logo {
  width: 200px;
  height: 200px;
}

@media screen and (max-width: 768px) {
  .job-offer-logo {
    width: 100px;
    height: 100px;
  }
}
.mh-315 {
  max-height: 315px;
}
.mt-n4 {
  margin-top: -4%;
}

.background-offre {
  background-image: linear-gradient(transparent, white),
    url("../../../public/assets/images/header-offre.png");
  background-size: cover;
  height: 20vw;
}
