.btn {
  padding: 10px 20px;
  border: 1px solid transparent;

  &:hover {
    cursor: pointer;
  }
}

.btn-primary {
  --bs-btn-bg: #4628dd;
  --bs-btn-border-color: #4628dd;
  --bs-btn-hover-bg: #4628dd;
  --bs-btn-hover-border-color: #4628dd;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-bg: #4628dd;
  --bs-btn-active-border-color: #4628dd;
  --bs-btn-disabled-bg: #cacaca;
  --bs-btn-disabled-border-color: #cacaca;

  &:hover {
    -webkit-box-shadow: 0px 0px 1px 5px rgba(70, 40, 221, 0.2);
    box-shadow: 0px 0px 1px 5px rgba(70, 40, 221, 0.2);
  }
}

.btn-secondary {
  --bs-btn-color: #4628dd;
  --bs-btn-bg: rgba(70, 40, 221, 0.04);
  --bs-btn-border-color: rgba(70, 40, 221, 0.04);
  --bs-btn-hover-color: #4628dd;
  --bs-btn-hover-bg: rgba(70, 40, 221, 0.06);
  --bs-btn-hover-border-color: rgba(70, 40, 221, 0.14);
  --bs-btn-active-color: #4628dd;
  --bs-btn-active-bg: rgba(70, 40, 221, 0.06);
  --bs-btn-active-border-color: rgba(70, 40, 221, 0.06);
  --bs-btn-disabled-color: rgba(70, 40, 221, 0.5);
  --bs-btn-disabled-bg: #cacaca;
}

.btn-violet {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #a428dd;
  --bs-btn-border-color: #a428dd;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #a428dd;
  --bs-btn-hover-border-color: rgba(164, 40, 221, 0.14);
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #a428dd;
  --bs-btn-active-border-color: #a428dd;
  --bs-btn-disabled-color: rgba(70, 40, 221, 0.5);
  --bs-btn-disabled-border-color: #cacaca;

  &:hover {
    -webkit-box-shadow: 0px 0px 1px 5px rgba(164, 40, 221, 0.2);
    box-shadow: 0px 0px 1px 5px rgba(164, 40, 221, 0.2);
  }
}

.btn-yellow {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #dba02a;
  --bs-btn-border-color: #dba02a;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #dba02a;
  --bs-btn-hover-border-color: rgba(219, 160, 42, 0.14);
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #dba02a;
  --bs-btn-active-border-color: #dba02a;
  --bs-btn-disabled-color: rgba(70, 40, 221, 0.5);
  --bs-btn-disabled-border-color: #cacaca;

  &:hover {
    -webkit-box-shadow: 0px 0px 1px 5px rgba(219, 160, 42, 0.2);
    box-shadow: 0px 0px 1px 5px rgba(219, 160, 42, 0.2);
  }
}
