.job-block-title {
  font-family: "Rubik", sans-serif;
  font-size: 20px;
  font-weight: 500;
}

.job-block-logo {
  width: 100px;
  height: 100px;
}

.highlight {
  color: #dba02a;
}

.selected {
  background-color: #e8e3d4 !important;
}
